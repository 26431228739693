import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Resume from "./Resume";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Resume />
  </React.StrictMode>,
);


const oldStyle = {};


/**
 * Override the style of a node before printing, restoring it afterward.
 */
function printOverride(selector, style) {
    const node = document.querySelector(selector);
    oldStyle[selector] = node.style;
    for (let [key, value] of Object.entries(style)) {
        node.style[key] = value;
    }
}

/**
 * Restores the style of an overridden element.
 */
function restore(selector) {
    document.querySelector(selector).style = oldStyle[selector];
}


window.onbeforeprint = (event) => {
    printOverride('html', {background: 'white'});
    printOverride('.Resume', {background: 'white', margin: 0, padding: 0});
}


window.onafterprint = (event) => {
    restore('html');
    restore('.Resume');
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
