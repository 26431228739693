export const content = {
    name: "Carl Anderson",
    description: `
        A continuous learner, fully invested in using IT for the greater good.
        Throughout my career, I have usually carried a pager and I've always
        worked closely with, and around, data.  I heal, build, maintain, and
        appreciate systems.
    `,
    contact: {
        email: "carl.anderson@gmail.com",
        phone: "773-633-9575",
        address: {
            street: "522 Caldy Pl",
            city: "Madison",
            state: "WI",
            zip: "53711",
        },
        site: "",
    },
    work: [
        {
            company: "Anaconda",
            link: "https://www.anaconda.com/about-us",
            title: "Sr. DevOps Engineer",
            image: "/static/anaconda.png",
            events: [
                {
                    date: "2021-10-04",
                    label: "Started",
                },
            ],
            summary: {
                heading: "Platform Team (Remote Work)",
                paragraphs: [
                    "Migrated legacy systems to a modern, secure, and scalable architecture.",
                    "Used Terraform, Terraform Cloud, ArgoCD, Kubernetes, and Renovate to manage infrastructure, deployments, services, and dependency updates.",
                    "Worked with the CTO to overhaul the on-call response process for the whole company.  Participated in on-call and on-duty rotations for production services.",
                    "Wrote a serverless package build staging service using Cloudflare JS workers and a Python client SDK and API.  See: https://staging.continuum.io",
                    "Designed and built a secure package signing pipeline for package builds.",
                    "Wrote monitoring and alerting for services in DataDog and OpsGenie.",
                ]
            },
            sections: [
                {
                    description: "rewrote our on-call process",
                    bullets: [
                        "worked with CTO to design and implement a new way of doing on-call and conducting incident management for all of engineering",
                        "participated in Incident Command, engineering on-call, and team on-duty rotations",
                        "standardized the post-mortem process for incidents",
                        "consulted with engineering teams on using DataDog for monitoring services",
                    ],
                },
                {
                    description: "package building DevOps",
                    bullets: [
                        "designed and wrote a serverless package staging service for all created packages using Cloudflare JS workers and a KV store",
                        "oversaw the sunsetting of a critical piece of legacy tech-debt (the zeus server)",
                        "co-wrote the next-gen conda package index generator and channel cloning system for CDN propagation of community content",
                        "wrote the next-gen anaconda.org edge cache for all conda.anaconda.org package transactions using Cloudflare JS workers, R2, and the Cloudflare cache API",
                        "honorary team member and alumni",
                    ],
                },
                {
                    description: "security operations",
                    bullets: [
                        "member of the Security Guild",
                        "member of the CVE reviewers guild",
                        "1 of 6 members of a tiger team formed in response to an emergency root key rotation code red",
                        "implemented an on-prem HSM service using YubiHSM",
                        "wrote a CPE sync service for updates in NVD databases",
                    ],
                },
                {
                    description: "core platform DevOps",
                    bullets: [
                        "managed infrastructure in AWS, Cloudflare, DataDog, Vault, etc using Terraform and TerraformCloud",
                        "designed and launched an on-duty rotation for the team",
                        "owned the package-building DevOps services",
                        "helped to promote the use and adoption of DataDog for observability within the DevOps team and among external engineering teams",
                    ],
                },
                {
                    description: "other",
                    bullets: [
                        "wrote a Slack badge bot to award employees with various recognitions in their About Me profile section",
                        "wrote a GitHub issue scraper called 'feedstalker' to monitor package feedstock repository activity",
                        "helped organize an Advent of Code group every December",
                        "led the book club through several books and reading sessions",
                        "mentored several new hires through their onboarding and early work efforts",
                        "participated in interviews and hiring decisions",
                        "represented Anaconda in open source community groups such as conda, conda-forge, and pytorch",
                    ],
                },
            ],
        },
        {
            company: "Microsoft",
            link: "https://mspoweruser.com/microsoft-launches-healthcare-next-new-initiative-transform-health-care/",
            title: "Sr. Software Engineer",
            image: "/static/msft.png",
            summary: {
                heading: "Healthcare NExT Leadership",
                paragraphs: [
                    "Participated in open standards processes with the HL7 Argonaut Project.",
                    "Facilitated the HL7 FHIR SMART Web Messaging project.",
                    "Enabled asymmetric key auth for SMART App Launch v2 in the official CDS Hooks sandbox.",
                    "Learned javascript and React after avoiding web programming for 20+ years.",
                    "Implemented oauth2 authentication demos for Single Page Apps using oauth2+PKCE.",
                    "Presented at conferences (in Amsterdam and virtually during COVID).",
                    "Worked remotely from home during COVID (for about 1 year).",
                ],
            },
            events: [
                {
                    date: "2019-07-22",
                    label: "Started",
                },
                {
                    date: "2021-10-01",
                    label: "Last day",
                },
            ],
            sections: [
                {
                    description: "",
                    bullets: [],
                },
            ],
        },
        {
            company: "Health Catalyst",
            link: "https://www.healthcatalyst.com/insights/how-touchstone-transforms-healthcare-benchmarking",
            title: "Sr. Research Engineer",
            image: "/static/hc.png",
            summary: {
                heading: "Touchstone Team (Remote Work)",
                paragraphs: [
                    "Technical lead for data ingestion of the Touchstone Data Pipeline which aggregated billions of rows of participant hospital data daily, centralizing it into an Azure SQL Data Warehouse using polybase, python, bash, powershell, sftp, gpg, golang, SQL, and RabbitMQ.",
                    "Wrote a client-side data extractor using powershell, BCP, sftp, and GPG, to prepare participant data for processing and delivery to a central system.",
                    "Wrote the data-processing backend for Touchstone participant data extracts using python, GPG, and Azure SQL DataWarehouse APIs.",
                    "Developed a hiring framework with an emphasis on eliminating unconscious bias in the early phase of candidate screening for the Touchstone team.",
                    "As part of the Research Team, I wrote several proof-of-concept apps, including a geolocation service using open source tools.",
                    "Discovered a novel method to extract polypharmacy scores for patients using only SQL, which was highly predictive for CAD analysis models.",
                    "Presented several Lunch and Learns on SQL topics such as Effective Use of CTEs, Calculating Polypharmacy in SQL, and Linear Regression in SQL.",
                    "Worked with senior leadership to establish a post-mortem standard (we called it 'SOAP'), presenting it to the whole company and consulting with groups wishing to use it.",
                    "DevOps champion - lead book club readings of 'The DevOps Handbook', 'The Phoenix Project', and 'Measure What Matters'.",
                    "Mentored by CEO, Dan Burton, in the company-wide mentorship program.",
                    "Worked remotely for the duration of my employment (from California and from Wisconsin).",
                ],
            },
            events: [
                {
                    date: "2014-03-17",
                    label: "Started",
                },
                {
                    date: "2019-07-19",
                    label: "Last day",
                },
            ],
            sections: [
                {
                    description: "Leadership",
                    bullets: [
                        "Promoted a standard HC postmortem process (SOAP) company-wide.",
                        "Developed a hiring process to mitigate unconscious bias.",
                        "Gave recorded talks on various SQL techniques, including simple linear regression, developing with CTEs, and calculating polypharmacy.",
                        "Mentored by CEO, Dan Burton.",
                    ],
                },
            ],
        },
        {
            company: "Google",
            link: "https://cloud.google.com/beyondcorp-enterprise",
            title: "SRE SWE",
            image: "/static/google.png",
            summary: {
                heading: "CI-SRE Team",
                paragraphs: [
                    "Used python, borg, and BigTable to aggregate NTP synchronization state from the entire fleet of 100,000 global, production computers.",
                    "Wrote monitoring and alerting for production services.",
                    "Participated in on-call (5 minute response SLA) and on-duty (24 hour response SLA) rotations.",
                    "Promoted to L4 SRE-SWE.",
                    "Designed and wrote a DHCP config generation service for production use at all corp locations.",
                    "Python Readability Reviewer.",
                    "Co-author of nsscache (which is now open-sourced).",
                    "Launched my 20% project as an open source tool; Advanced Shell History.",
                    "Provided ad-hoc legal discovery requests for Gmail.",
                    "Awarded Peer Bonuses (4x) for above-and-beyond efforts.",
                    "One of 100 employees trusted with 'root' at Google.",
                ],
            },
            events: [
                {
                    date: "2010-07-26",
                    label: "Started",
                },
                {
                    date: "2014-03-14",
                    label: "Last day",
                },
            ],
            sections: [
                {
                    description: "Achievements",
                    bullets: [
                        "Promotion from L3 to L4, 2013",
                        "Python readability reviewer",
                        "Launched 20% project as open source, Advanced Shell History",
                    ],
                },
            ],
        },
        {
            company: "Northwestern University",
            link: "",
            title: "Data Architect",
            image: "/static/nu.png",
            summary: {
                heading: "Enterprise Data Warehouse",
                paragraphs: [
                    "Implemented and submitted PQRI (before PQRS) for 19 measures to CMS.",
                    "Wrote and maintained ETL for Intellidose, eClinicalWorks, and a weekly Death Master Index extract.",
                    "Various quarter-long initiatives with nephrology, ob-gyn, and the faculty foundation - NMFF.",
                ],
            },
            events: [
                {
                    date: "2009-02-26",
                    label: "Started",
                },
                {
                    date: "2010-07-01",
                    label: "Last day",
                },
            ],
            sections: [
                {
                    description: "",
                    bullets: [],
                },
            ],
        },
        {
            company: "Chicago Mercantile Exchange",
            link: "",
            title: "PSG Engineer II",
            image: "/static/cme.png",
            summary: {
                heading: "Production Support Group",
                paragraphs: [
                    "Product owner and L2 on-call for NEMO, the New Engine MOnitor for matching inbound and outbound trade messages.",
                    "Excellence Award winner for my work implementing a C++ FixFAST message decoder in a week.",
                    "Ad-hoc SQL-driven investigations of trading and engine anomalies.",
                    "24x7 On-call rotation participant for various production services.",
                ],
            },
            events: [
                {
                    date: "2007-05-21",
                    label: "Started",
                },
                {
                    date: "2009-01-22",
                    label: "Last day",
                },
            ],
            sections: [
                {
                    description: "",
                    bullets: [
                        "Product support owner and L2 oncall for NEMO, the New Engine MOnitor for matching inbound and outbound trade messages.",
                        "Excellence award for work implementing a C++ FixFAST message decoder in a week.",
                        "Ad-hoc SQL-driven investigation.",
                    ],
                },
            ],
        },
        {
            company: "Ribstone Systems, Inc.",
            link: "https://www.onediscovery.com/about-us/",
            title: "Release Engineer",
            image: "/static/ribstone.png",
            summary: {
                heading: "Accomplishments and Duties",
                paragraphs: [
                    "Rewrote the device OS and software installer (and updater) utilities using a gentoo minimal cd iso, bash, and Java.",
                    "On-call rotation for customer support.",
                    "Developed an early CI/CD build system for generating ISO installer images, making them available to QA over NFS immediately after build.",
                    "Wrote software features for a JBoss-driven linux app in Java.",
                ],
            },
            events: [
                {
                    date: "2005-07-14",
                    label: "Started",
                },
                {
                    date: "2007-05-19",
                    label: "Last day",
                },
            ],
            sections: [
                {
                    description: "",
                    bullets: [],
                },
            ],
        },
        {
            company: "Johnson Controls, Inc.",
            link: "https://www.johnsoncontrols.com/suppliers/building-technologies-and-solutions/supplier-partnership-experience",
            title: "Intern Analyst, Strategic Procurement",
            image: "/static/jci.png",
            summary: {
                heading: "Projects and Duties",
                paragraphs: [
                    "Designed an MS Access database for maintaining 'frontliner' survey call history and scores.",
                    "Report automation project using Tomcat, Java, JSP, and SQL.",
                    "Generated reports and emailed to executives weekly and monthly.",
                    "Designed new reports in Oracle, Excel, and Access using procurement data.",
                ],
            },
            events: [
                {
                    date: "2003-12-18",
                    label: "Started",
                },
                {
                    date: "2005-06-30",
                    label: "Last day",
                },
            ],
            sections: [
                {
                    description: "",
                    bullets: [],
                },
            ],
        },
        {
            company: "UW Milwaukee",
            link: "https://uwm.edu/engineering/departments/computer-science/",
            title: "Undergrad Student Assistant",
            image: "/static/uwm.png",
            summary: {
                heading: "CS 252, Intermediate C++",
                paragraphs: [
                    "Graded student homework.",
                    "Helped design new assignments.",
                    "Maintained a 15,000-line C++ MUD final project codebase.",
                ],
            },
            events: [
                {
                    date: "2001-01-08",
                    label: "Started",
                },
                {
                    date: "2002-08-20",
                    label: "Last day",
                },
            ],
            sections: [
                {
                    description: "",
                    bullets: [],
                },
            ],
        },
    ],
    education: [
        {
            school: "UW Extended Campus",
            degree: "MS in Data Science",
            link: "https://datasciencedegree.wisconsin.edu/",
            image: "/static/uwex2.png",
            events: [
                {
                    date: "2017-12-18",
                    label: "🚀 Enrolled",
                },
                {
                    date: "2020-05-18",
                    label: "⏸ Paused",
                },
            ],
        },
        {
            school: "University of Chicago",
            degree: "MS in Computer Science",
            link: "https://masters.cs.uchicago.edu/",
            image: "/static/uchi.png",
            events: [
                {
                    date: "2007-07-01",
                    label: "🚀 Enrolled",
                },
                {
                    date: "2010-06-21",
                    label: "🏆 Graduated",
                },
            ],
        },
        {
            school: "UW Milwaukee",
            degree: "BS in Computer Science",
            link: "https://uwm.edu/engineering/academics-2/departments/computer-science/",
            image: "/static/uwm.png",
            events: [
                {
                    date: "2000-09-10",
                    label: "🚀 Enrolled",
                },
                {
                    date: "2004-12-16",
                    label: "🏆 Graduated",
                },
            ],
        },
    ],
    tools: [
        "linux", "git", "azure", "aws", "kubernetes", "docker", "FHIR", "React", "Cloudflare",
    ],
    languages: [
        "python", "javascript", "SQL", "bash", "c++", "go", "powershell", "java",
    ],
    recognition: [
        "Eagle Scout", "featured author on sqlservercentral.com", "invited speaker at FHIR DevDays", "Anaconda HackDays Award",
    ],
    fun: [
        "🎱 PoolCV (billiards computer vision)", "🐚 Advanced Shell History", "🦡 BadgerBot", "☕ coffee roasting", "📖 reading", "🧘 taijiquan", "🪚 carpentry", "🗡 metalwork",
    ],
    community: [
        "Scouts BSA Adult Leader", "Cubmaster", "Madison HealthTech MeetUp (presenter and participant)", "Recreational Billiards League",
    ]
};
