import { Fragment } from "react";
import { getPeriods, Timeline, TimeSpan } from "./Timeline.js";
import { content } from "./content.js";
import "./Education.css";


function Degree({program}) {
    return (
        <tr className="DegreeProgram">
            <td>
                <div>{program.school}</div>
                <a href={program.link}>{program.degree}</a>
            </td>
        </tr>
    );
}


function TimePeriod({program, height, label1, label2}) {
    if (!program) {
        return (
            <TimeSpan height={height} />
        );
    }
    return (
        <TimeSpan height={height} event1={label1} event2={label2}>
            <Degree program={program} />
        </TimeSpan>
    );
}


function Education() {
    // Convert the program content into a format needed by the components.
    const periods = getPeriods(content.education, 3000);
    return (
        <Timeline title="Education">
            {periods.map(([program, pixels, enrolled, graduated], index) =>
                <Fragment key={index}>
                    <TimePeriod
                        program={program}
                        height={pixels}
                        label1={enrolled}
                        label2={graduated}
                    />
                </Fragment>
            )}
        </Timeline>
    );
}


export default Education;
