/**
 * TODO
 *   [ ] - [click to see more] button in upper right region of element
 *   [ ] - include the company logo in each box for correlation with the timeline
 *   [ ] -
 */
import {Fragment} from "react";
import {Edge, Timeline, TimeSpan, getPeriods} from "./Timeline.js";
import {content} from "./content.js";
import "./Experience.css";


function Role({company, title, url}) {
    return (
        <div className="Role">
            <a href={url}>
                <div className="Employer">
                    {company}
                </div>
            </a>
            <div className="Title">
                {title}
            </div>
            <br></br>
        </div>
    )
}


function Summary({heading, summary}) {
    return (
        <div className="Summary">
            <div className="Heading">
                {heading}
            </div>
            <ul className="Paragraphs">
                {summary.map((text, index) =>
                    <Fragment key={index}>
                        <li className="Bullet">
                            {text}
                        </li>
                    </Fragment>
                )}
            </ul>
        </div>
    );
}


function Job({role}) {
    return (
        <tr className="Job" company={role.company}>
            <td className="JobDetails">
                <Role company={role.company} title={role.title} url={role.link} />
                <Summary heading={role.summary.heading} summary={role.summary.paragraphs} />
            </td>
            <Edge />
        </tr>
    );
}


function TimePeriod({job, height, label}) {
    if (!job) {
        return (
            <TimeSpan height={height} />
        );
    }
    return (
        <TimeSpan height={height} event1={label} >
            <Job role={job} />
        </TimeSpan>
    );
}


function Experience() {
    const pixels = 3000;
    const periods = getPeriods(content.work, pixels);

    // Combine the start and stop dates of each job into one label.
    const career = periods.map(([role, pixels, started, ended]) => {
        if (!role) {
            return [role, pixels, started, ended];
        }
        const getDate = (d) => `${d.getFullYear()}/${1 + d.getMonth()}`;
        const label = `${getDate(ended.date)} to ${getDate(started.date)}`;
        return [role, pixels, {image: started.image, label}];
    });

    return (
        <Timeline title="Experience">
            {career.map(([role, pixels, label], index) =>
                <Fragment key={index}>
                    <TimePeriod
                        job={role}
                        height={pixels}
                        label={label}
                    />
                </Fragment>
            )}
        </Timeline>
    );
}


export default Experience;