/**
 * TODO
 *  [ ] Badges box above contact info with links to GH, Twitter, SO, etc
 *  [ ] Experience and Education headings should be sticky and follow while scrolling
 *  [ ] safari support (or at least, detection and disclaimer)
 *  [ ] report an issue link to repo Issues page
 *  [ ] hilbert pattern for area behind the page
 */
import "./Resume.css";
import {content} from "./content.js";
import "@fontsource/comfortaa";
import ContactInfo from "./ContactInfo.js";
import Education from "./Education.js";
import Experience from "./Experience.js";
import Skills from "./Skills.js";


function Resume() {
    const today = (new Date()).toDateString();
    return (
        <table className='Resume'>
            <thead>
                <tr>
                    <th>
                        <h1 className="Name">{content.name}</h1>
                    </th>
                    <th><div className="Today">{today}</div></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div className="Objective">{content.description}</div>
                    </td>
                    <td>
                        <ContactInfo />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Experience />
                    </td>
                    <td>
                        <Education />
                    </td>
                </tr>
                <tr>
                    <Skills />
                </tr>
            </tbody>
        </table>
    );
}

export default Resume;