/**
 * A section containing contact information.
 */
import "./ContactInfo.css";
import {content} from "./content.js";

function ContactInfo() {
    const contact = content.contact;
    const mailto = `mailto:${contact.email}`;
    const address = contact.address;
    const location = `${address.city}, ${address.state} ${address.zip}`;
    return (
        <section className="ContactInfo">
            <a href={mailto}>{contact.email}</a>
            <div>{contact.phone}</div>
            <div>{address.street}</div>
            <div>{location}</div>
            <a href="https://oo.bara.boo">https://oo.bara.boo</a>
        </section>
    );
}

export default ContactInfo;