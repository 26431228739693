/**
 * TODO
 *  [ ] - import data from content.js
 */

import "./Skills.css";
import {content} from "./content.js";
import "@fontsource/comfortaa";


function Skills() {
    return (
        <td className="Skills" colSpan="2">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className="SkillsHeading">Skills & Awards</div>
                        </td>
                        <td>
                            <table>
                                <thead className="SkillCategories">
                                    <tr>
                                        <th>Languages</th>
                                        <th>Tools</th>
                                        <th>Recognition</th>
                                        <th>Fun</th>
                                        <th>Community</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="SkillDetails">
                                        <td><div>{content.languages.join(', ')}</div></td>
                                        <td><div>{content.tools.join(', ')}</div></td>
                                        <td><div>{content.recognition.join(', ')}</div></td>
                                        <td><div>{content.fun.join(', ')}</div></td>
                                        <td><div>{content.community.join(', ')}</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    );
}


export default Skills;
