/**
 * TODO
 *  [x] Single LeftBubble option, centered in the TimePeriod
 *    [ ] keep the start and stop labels fixed where they are
 *  [ ] Add a 5px spacer to the end of each timeline
 */
import './Timeline.css';


function Bubble({image}) {
    const imageUrl = process.env.PUBLIC_URL + image;
    return (
        <td className="Bubble">
            <table>
                <tbody>
                    <tr>
                        <td className="BubbleSegment"></td>
                        <td className="BubbleBorder">
                            <img alt={image} className="BubbleIcon" src={imageUrl}></img>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    );
}


function Edge({label}) {
    return (
        <td className="Edge">{label}</td>
    );
}


/**
 * Displays an icon to the left of a Timeline, with a segment connecting it to
 * the Timeline at a point in time.
 */
function LeftBubble({image, label}) {
    return (
        <tr className='LeftBubble'>
            <Bubble image={image} />
            <Edge label={label} />
        </tr>
    );
}


function toDays(ms) {
    return ms / 1000 / 60 / 60 / 24;
}


function daysSince(date) {
    return toDays(new Date() - date);
}


/**
 * Extracts event labels and the number of days from a list of content records.
 */
function getEventDays(eventRecords) {
    return eventRecords.map(eventRecord => {
        // Compute the labels needed for the bubbles.
        const labels = eventRecord.events.map(event => {
            const date = new Date(event.date);
            return {
                date,
                image: eventRecord.image,
                label: `${date.getFullYear()} ${event.label}`,
            }
        });
        // Unfinished programs show an 'in progress' label.
        if (labels.length === 1) {
            labels.push({
                date: new Date(),
                image: eventRecord.image,
                label: 'today',
            });
        }
        // Compute the total number of days spent in the program and scale the
        // length of the relative height of each program proportionately.
        const ms = labels[1].date - labels[0].date;
        return [eventRecord, toDays(ms), ...labels];
    });
}


/**
 * Returns a list of time period data from a list of event content.
 */
function getPeriods(eventContents, pixels) {
    const events = [...getEventDays(eventContents)];

    // Insert gaps between events, setting the day duration of each gap.
    const periods = [];
    while (events.length > 0) {
        const oldest = events.pop();
        periods.push(oldest);

        // No gap needed, the last event is ongoing.
        if (events.length === 0 && oldest[0].events.length < 2) {
            break;
        }

        // A gap is needed either between two events, or between the last event
        // and 'right now'.
        const next = events.slice(-1)[0];
        const nextStart = next ? next[2].date : new Date();
        periods.push([undefined, toDays(nextStart - oldest[3].date)]);
    }

    // Scale the periods to fit within the desired pixels, using the number of
    // pixels per day as a factor.
    const pixelsPerDay = pixels / daysSince(periods[0][2].date);
    periods.forEach(period => {
        period[1] *= pixelsPerDay;
        if (period[1] < 0) {
            console.error("Detected date overlap - check source content.");
        }
        if (period[1] < 105 && period[0]) {
            console.warn(
                "Detected a time period without enough space to render."
            );
        }
    });

    // Emit a warning if the heights don't add up right.
    const actual = periods.reduce((total, height) => total + height[1], 0);

    // Sanity checks.
    if (!actual) {
        console.error(
            'Error summing timeline period pixels', [...periods].reverse()
        );
    }
    if (Math.abs(actual - pixels) > 0.1) {
        console.error(`Desired height=${pixels}, Actual height=${actual}`);
    }

    // The periods were stacked in reverse order, so they must be reversed
    // before returning.
    return periods.reverse();
}


/**
 * Displays an event description surrounded by two left bubbles marking the
 * start and stop (event1 happens before event2).
 */
function TimeSpan({children, height, event1, event2}) {
    // If the TimeSpan represents a Gap between events.
    if (!event1 && !event2) {
        return (
            <tbody className='TimeSpan' style={{height: `${height}px`}}>
                <tr className="Spacer">
                    <td style={{height: '100%'}}></td>
                    <Edge/>
                </tr>
                {children}
            </tbody>
        );
    }
    // Return a timespan with a single, centered label.
    if (!event2) {
        return (
            <tbody className="TimeSpan" style={{height: `${height}px`}}>
                <tr className="Spacer">
                    <td style={{height: '50%'}}></td>
                    <Edge />
                </tr>
                {LeftBubble({image: event1.image, label: event1.label})}
                {children}
                <tr className="Spacer">
                    <td style={{height: '50%'}}></td>
                    <Edge />
                </tr>
            </tbody>
        )
    }
    // Return the labeled events.
    return (
        <tbody className="TimeSpan" style={{height: `${height}px`}}>
            {LeftBubble({image: event2.image, label: event2.label})}
            <tr className="Spacer">
                <td style={{height: '50%'}}></td>
                <Edge />
            </tr>
            {children}
            <tr className="Spacer">
                <td style={{height: '50%'}}></td>
                <Edge />
            </tr>
            {LeftBubble({image: event1.image, label: event1.label})}
        </tbody>
    );
}


/**
 * Displayed on a page as a vertical bar with labeled events, which are
 * separated by scaled distance.
 *
 * @returns Timeline component.
 */
function Timeline({title, children}) {
    // These various class names are needed to be able to inject styling in
    // the CSS documents where timelines are created.  See Education.css.
    const heading = `${title}-heading`;
    const now = `${title}-year`;
    const timeline = `${title}Timeline`;
    const section = `${title}-section`;

    const year = (new Date()).getFullYear();
    return (
        <section className={title}>
            <table className={timeline}>
                <thead>
                    <tr className={heading}>
                        <th className={section}>{title}</th>
                        <th className={now}>{year}</th>
                    </tr>
                </thead>
                {children}
            </table>
        </section>
    );
}


export {getPeriods, Bubble, Edge, LeftBubble, Timeline, TimeSpan};
